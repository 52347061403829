"use strict";

var $ = require("jquery");
var common = require("../../common");
var BootstrapDialog = require("bootstrap3-dialog");

function lesson_to_html(l) {
	const parts = [
		l.cou_code ? {title: "Course Code", val: fix_html_ents(l.cou_code)} : null,
		{title: "Course Name", val: fix_html_ents(l.cou_name)},
		l.branch ? {title: "Branch", val: l.branch} : null,
		{title: "Date", val:        l.date_fmt},
		{title: "Time", val:        l.start_time_fmt},
	].filter(p => p !== null);

	const result = $("<div/>");

	for (const part of parts) {
		const line = $("<div/>");
		$(line).append($("<h5/>").text(part.title + ": "));
		$(line).append($("<span/>").text(part.val));
		$(result).append(line);
	}

	return result;
}

function on_click_confirm(from_lesson_id, to_lesson_id) {
	$("#confirm-makeup-btn").addClass("disabled");

	const makeup = {
		from_lesson_id,
		to_lesson_id,
	};

	const remark_val = $("#remarks").val();
	if (remark_val) {
		makeup.remark = remark_val;
	}

	api_user.post_data("/student/attendance/add_makeup", {
		makeup,
	}, (err, res) => {
		console.log(err);
		console.log(res);
		if (err) {
			let err_nss;
			if (["too_late", "max_student_count", "makeuplesson_already_had", "skiplesson_already_had"].indexOf(err) == -1) {
				err_nss = "generic_error_occurred"
			} else {
				err_nss = "makeup_error_" + err;
			}
			$(".error-detail").text(nss("106:" + err_nss) + ` (Error code: ${err})`);
			$(".bad").removeClass("hide");
		} else {
			$(".ok").removeClass("hide");
			$("#go-back-btn").text("Done").attr("href", "/student/makeups");
		}
	});
}

function show_error_dialog(message) {
	BootstrapDialog.show({
		message,
		title: nss("generic_error_occurred"),
		cssClass: "very-high-zindex",
		type: BootstrapDialog.TYPE_WARNING,
		buttons: [
			{
				label: nss("generic_ok"),
				action: function(dialogItself){
					dialogItself.close();
				}
			}
		]
	});
}

module.exports = class MakeupConfirmPage {
	constructor (frontend) {
		this.state = {
			frontend: frontend
		};
	}

	async load_async() {
		const args = common.get_args();
		const from_clid = args.lesson_from;
		const to_clid = args.lesson_to;

		const back_target = "/student/lessons?mode=add_makeup_to&lesson_from=" + from_clid + ( args.course ? ("&course=" + args.course) : "");
		$("#go-back-btn").attr("href", back_target);

		const [from, to] = await Promise.all([
			api_user.fetch_data_promise("/student/course/get_lesson_one_v2?clid=" + from_clid + "&is_makeup_confirm_mode=1"),
			api_user.fetch_data_promise("/student/course/get_makeup_target_details?clid=" + to_clid)
		]);

		$("#makeup-from-info").append(lesson_to_html(from));
		$("#makeup-to-info").append(lesson_to_html(to));

		const show_remarks = this.state.frontend.state.feature_config.makeup_remarks_required;

		$("#confirm-makeup-btn").on("click", e => {
			e.preventDefault();

			if (show_remarks && !$("#remarks").val().trim()) {
				show_error_dialog(nss("makeup_must_enter_remarks"));
				return;
			}

			on_click_confirm(from_clid, to_clid);
		});

		$("#confirm-makeup-btn").removeClass("disabled");

		if (show_remarks) {
			$("#remarks-part").removeClass("hide");
			$("#remarks").removeAttr("disabled");
		}
	}
};